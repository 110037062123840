import { InteractionLogger, googleAds, facebookAds, ReferralMapper } from '@jeroen.bakker/just-attribute';
import type { Interaction, InteractionMiddleware } from '@jeroen.bakker/just-attribute';
import fetch from '../../api/fetch';
import { localStorage } from '../../util/storage';
import { window } from '../../globals';

const interactionLogger: InteractionLogger = new InteractionLogger({
    // 20 interactions should be plenty to analyse most traffic,
    // and we use last (non-direct) attribution anyway
    logLimit: 20,
    // This is mostly in case we aren't able to clear the log after a conversion
    // interactions over 30 days ago should not be relevant at all for attribution purposes
    logRetentionTime: InteractionLogger.DAY * 30,
    // Set the session timeout to 4 hours to mimic GA
    sessionTimeout: InteractionLogger.MINUTE * 60 * 4,
});

if (window) {
    window.interactionLogger = interactionLogger;
}

// Adding the current URL to the interaction means we get a nice log over landing pages in our interaction log
const logLandingPage: InteractionMiddleware = (interaction: Interaction, url ?: URL) => ({
    ...interaction,
    // Log the landing page, without query string since we already log the parameters
    pageId: window?.pageId,
    url: url?.toString().split(/[?#]/)[0],
});

const transactionalExclusion: InteractionMiddleware = (interaction: Interaction) => {
    // Transactional interactions should not cause a change in attribution since they aren't related to marketing
    if (interaction.campaign?.toLowerCase().startsWith('trans-')) {
        return {
            ...interaction,
            excluded: true,
            excludedBy: 'transactional filter',
        };
    }

    return interaction;
};

// We do not want to attribute anything to affiliates in the hour after someone first visits our checkout
const affiliateExclusion: InteractionMiddleware = (interaction: Interaction) => {
    const oneHourInMilliseconds = 60 * 60 * 1e3;
    const checkoutTimestamp = localStorage.getItem('checkout_timestamp');

    if (checkoutTimestamp && Date.now() - Number(checkoutTimestamp) < oneHourInMilliseconds) {
        // We only want to exclude affiliate attributions
        if (interaction.medium?.toLowerCase() !== 'affiliate') {
            return interaction;
        }

        return {
            ...interaction,
            excluded: true,
            excludedBy: 'affiliate checkout exclusion',
        };
    }

    // If there was no current checkout timestamp, or it has expired, and we're currently in the checkout
    // store the new time when we've entered the checkout
    if (window?.pageType.toLowerCase() === 'checkout') {
        localStorage.setItem('checkout_timestamp', String(Date.now()));
    }

    return interaction;
};

const storeAttributionLog = () => {
    fetch('/analytics/interaction-log', {
        body: JSON.stringify({ log: interactionLogger.interactionLog() }),
        headers: {
            'Content-Type': 'application/json',
        },
        keepalive: true,
        method: 'POST',
    }).catch(() => null);
};

interactionLogger.registerInteractionMiddleware(logLandingPage);
interactionLogger.registerInteractionMiddleware(googleAds);
interactionLogger.registerInteractionMiddleware(facebookAds);
interactionLogger.registerInteractionMiddleware(ReferralMapper.newSearchEngineMiddleware());
interactionLogger.registerInteractionMiddleware(ReferralMapper.newSocialNetworkMiddleware());
interactionLogger.registerInteractionMiddleware(transactionalExclusion);
interactionLogger.registerInteractionMiddleware(affiliateExclusion);

interactionLogger.onAttributionChange(storeAttributionLog);

interactionLogger.pageview();

export default interactionLogger;
