import type { ConsentService, Tracker } from '@yoursurprise/segment-analytics';
import { consentMethods, ConsentServiceFactory } from '@yoursurprise/segment-analytics';
import Cookie from 'js-cookie';
import type { InteractionLogger } from '@jeroen.bakker/just-attribute';
import { z } from 'zod';
import fetch from '../../api/fetch';
import type { Potential, ProductData } from '../../types';
import wait from '../../util/wait';
import ProductListEventsBinderFactory from '../../segment/ProductListEvents/ProductListEventsBinderFactory';
import type ProductListEventsBinder from '../../segment/ProductListEvents/ProductListEventsBinder';
import { document, window } from '../../globals';
import type ErrorLogger from '../../error-logger/ErrorLogger';
import type UrlDataService from '../UrlDataService/UrlDataService';
import { Ga4ContextEnricher } from '../../segment/Ga4ContextEnricher';
import Ga4Context from '../../segment/Ga4Context';
import { FacebookContextEnricher } from '../../segment/FacebookContextEnricher';
import { WebshopContextContextEnricher } from '../../segment/WebshopContextContextEnricher';
import openCookieDialog from '../../../react/cookie/CookieDialogControls';
import { isObject } from '../../typeguards';
import { OptimizelyContextEnricher } from '../../segment/OptimizelyContextEnricher';

interface SetupData {
    anonymousId: string;
    ga4ClientId?: string;
    ga4SessionId?: string;
    ga4SessionNumber?: string;
    interests?: string[];
    userId?: string | null;
}

const PageDataSchema = z.object({
    __utmz: z.string(),
    pageId: z.string().optional(),
    pageName: z.string().optional(),
    pageType: z.string(),
    path: z.string().optional(),
    topLevelPageId: z.number().optional(),
    xid: z.string().optional(),
});

const PageSetupSchema = z.object({
    pageData: PageDataSchema.optional(),
    sgLocale: z.string(),
    userId: z.string().nullable().optional(),
});

type PageSetupResponse = z.infer<typeof PageSetupSchema>;
type PageData = PageSetupResponse['pageData'];

function assertIsValidPageSetupResponse(data: Potential<PageSetupResponse>): asserts data is PageSetupResponse {
    PageSetupSchema.parse(data);
}

export default class AnalyticsService {
    private readonly consentService: ConsentService;
    private readonly setupEndpoint = 'analytics/setup';
    private readonly pendingMessagesEndPoint = 'analytics/pendingMessages';
    private readonly channableParameter = 'channable';
    private readonly STORAGE_KEY_FIRST_PAGEVIEW = '__first_pageview__';
    private readonly PAGE_TYPE_PRODUCT = 'Product';
    private readonly PAGE_TYPE_CHECKOUT = 'Checkout';
    private readonly PAGE_TYPE_SEARCH = 'Search';
    private readonly CONSENT_GRANTED_EVENT = 'consentGranted';
    private readonly CONSENT_DENIED_EVENT = 'consentDenied';
    private readonly productListEventsBinder: ProductListEventsBinder;

    // @todo: We should add the consent tracking information client side as well
    // probably to the 'track' function of @yoursurprise/segment-analytics
    public constructor(
        private readonly baseUrl: string,
        private readonly tracker: Tracker,
        private readonly urlDataService: UrlDataService,
        private readonly errorLogger: ErrorLogger | null,
        private readonly interactionLogger: InteractionLogger,
    ) {
        this.consentService = ConsentServiceFactory.create('consent', fetch);
        this.productListEventsBinder = ProductListEventsBinderFactory.createProductEventsBinder(tracker);

        const { originalUrl } = this.urlDataService;

        if (originalUrl && AnalyticsService.isFacebookVisitWithMissingAttribution(originalUrl)) {
            AnalyticsService.addFallbackFacebookUTMParameters(originalUrl);
        }
    }

    public triggerConsentGrantedEvent(): void {
        document?.dispatchEvent(new CustomEvent(this.CONSENT_GRANTED_EVENT));
    }

    public async initAnalytics(): Promise<void> {
        if (!document || !window) {
            return;
        }
        const { ga4LoadWithSegment, pageType } = window;

        document?.addEventListener(this.CONSENT_GRANTED_EVENT, () => {
            this.tracker.load('', {
                integrations: {
                    all: true,
                    'Google Analytics 4 Web': ga4LoadWithSegment ?? false,
                },
            });

            if (typeof window?.loadOldGtm === 'function') {
                window.loadOldGtm();
            }

            AnalyticsService.loadDelayedScripts();
        }, { once: true });

        if (await this.consentService.wasConsentGranted()) {
            this.triggerConsentGrantedEvent();
        } else if (await this.consentService.shouldShowInformation(pageType)) {
            this.showAnalyticsInformation();
        }

        await this.processTrackingConsentInputs();

        this.tracker.onReady(() => {
            if (!window) {
                return;
            }

            if (window.ga && window.ga_design_experiment) {
                window.ga('set', 'exp', window.ga_design_experiment);
            }

            Promise.race([
                this.setup(),
                wait(5000),
            ]).then(() => {
                this.processPendingMessages();
                this.trackProductViewedFromWindow();
                this.productListEventsBinder.processProducts();
                this.productListEventsBinder.processTopProducts();
            }).catch(() => null);

            // Expose the trackProductViewed function globally so legacy code can trigger the event
            window.trackProductViewed = this.trackProductViewed;
        });
    }

    private static loadDelayedScripts(): void {
        const delayedScripts = document?.getElementsByClassName('js-requiresConsent') || [];
        const scripts = Array.from(delayedScripts) as unknown as Array<{ dataset: { src: string } }>;

        scripts.forEach((delayedScript) => {
            if (!(delayedScript instanceof HTMLElement)) {
                return;
            }

            const {
                async: isAsync,
                defer: isDeferred,
                src: sourceUrl,
            } = delayedScript.dataset;

            if (sourceUrl && document) {
                // Scripts get loaded when added to the body (or when originally parsed)
                // so we can't load the script by simply adding a src attribute to the original element
                const newScript = document.createElement('script');

                if (typeof isAsync !== 'undefined') {
                    newScript.setAttribute('async', '');
                }

                if (typeof isDeferred !== 'undefined') {
                    newScript.setAttribute('defer', '');
                }

                newScript.setAttribute('src', sourceUrl);

                document.head.appendChild(newScript);
            }
        });
    }

    private async setup(): Promise<void> {
        const payload = await this.buildSetupPayload();

        if (payload === false) {
            return;
        }

        try {
            const response = await fetch(this.baseUrl + this.setupEndpoint, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });

            if (response.ok) {
                const pageSetupResponse = await response.json() as Potential<PageSetupResponse>;
                assertIsValidPageSetupResponse(pageSetupResponse);
                const additionalPageData = this.processSetupResponse(pageSetupResponse);
                const pageData = this.buildInitialPageData();
                Object.assign(pageData, additionalPageData);

                this.tracker.page(pageData);

                await window?.analytics?.register?.(Ga4ContextEnricher);
            }

            await window?.analytics?.register?.(FacebookContextEnricher);
            await window?.analytics?.register?.(OptimizelyContextEnricher);
            await window?.analytics?.register?.(WebshopContextContextEnricher);
        } catch (e) {
            this.errorLogger?.log(e as Error | string);
        }

        // Clear the interaction log after converting
        this.tracker.on('track', (event) => {
            if (event.toLowerCase() === 'order completed') {
                this.interactionLogger.clearLog();
            }
        });
    }

    private async buildSetupPayload(): Promise<false | SetupData> {
        const anonymousId = this.tracker.getAnonymousId();
        // A very simplistic way of detecting bots
        // crawlers often contain the word bot in their user agent string
        const uaIsBot = navigator.userAgent.toLowerCase().includes('bot');

        if (!anonymousId) {
            return false;
        }

        const payload: SetupData = { anonymousId };

        // If we suspect the user to be a bot then we don't use the URL as an identifying mechanism
        // this is due to leaking user IDs into generated crawlable URLs in the past
        if (this.urlDataService.userId && !uaIsBot) {
            payload.userId = this.urlDataService.userId;
        }

        if (this.urlDataService.interests) {
            payload.interests = this.urlDataService.interests;
        }

        await Ga4Context.setGa4Data().then(() => {
            const ga4Data = Ga4Context.getGa4Data();
            payload.ga4ClientId = String(ga4Data.ga4ClientId);
            payload.ga4SessionId = String(ga4Data.ga4SessionId);
            payload.ga4SessionNumber = String(ga4Data.ga4SessionNumber);
        });

        return payload;
    }

    private processSetupResponse(response: PageSetupResponse): unknown {
        if (response.userId !== this.tracker.getUserId()) {
            // If we have a local identity and it's not the same as the one we track server-side
            // we need to reset the local identity
            // either there are conflicting identities or the user has just logged out
            if (this.tracker.getUserId()) {
                this.tracker.reset();
            }

            // If we have a server-side identity and it's not the same as the one we track locally
            // we need to identify the local user
            // In case of conflicting identities the old local identity will have been reset above
            // otherwise the user has just logged in
            if (response.userId) {
                this.tracker.identify(response.userId);
            }
        }

        let additionalPageData = {};
        if (isObject(response.pageData)) {
            additionalPageData = response.pageData;
        }

        return additionalPageData;
    }

    private buildInitialPageData(): NonNullable<PageData> {
        let utmz = '';
        try {
            utmz = Cookie.get('__utmz') ?? '';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        const pageData: PageData = {
            __utmz: utmz,
            pageType: 'Other',
            path: document?.location.pathname,
        };

        if (window?.pageId) {
            pageData.pageId = String(window.pageId);
        }

        if (window?.pageName) {
            pageData.pageName = window.pageName;
        }

        if (window?.pageType) {
            pageData.pageType = window.pageType;
        }

        if (window?.topLevelPageId) {
            pageData.topLevelPageId = window.topLevelPageId;
        }

        if (document && 'xid' in document.body.dataset) {
            pageData.xid = document.body.dataset.xid;
        }

        // Add the search string to the path if we want to record it in Google Analytics
        // as it is stripped from the path by default
        if (pageData.pageType === this.PAGE_TYPE_SEARCH || document?.location.search.includes(this.channableParameter)) {
            const { search } = document?.location || { search: '' };

            if (search.length > 0) {
                pageData.path += search;
            }
        }

        return pageData;
    }

    private processPendingMessages(): void {
        const request = new XMLHttpRequest();

        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
                const messages = JSON.parse(request.responseText) as Array<{ event: string; integrations?: unknown; properties: Record<string, unknown> }>;

                messages.forEach((message) => {
                    let options;
                    if ('integrations' in message) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        options = { integrations: message.integrations };
                    }

                    this.tracker.track(message.event, message.properties, options);
                });
            }
        };

        request.open('GET', this.baseUrl + this.pendingMessagesEndPoint);
        request.send();
    }

    /**
     * @see https://segment.com/docs/spec/ecommerce/v2/#product-viewed
     * Tracks the viewed product from the global "productData", or "viewedProductList"
     * @see combinedArticle.js
     */
    private trackProductViewedFromWindow(): void {
        if (window?.pageType !== this.PAGE_TYPE_PRODUCT) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (typeof window?.productData === 'object' && window?.productData?.productId) {
            this.trackProductViewed(window.productData);
        }

        if (typeof window?.viewedProductList === 'object') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            window.viewedProductList.forEach((product) => {
                this.trackProductViewed(product);
            });
        }
    }

    private trackProductViewed = (productData: ProductData): void => {
        if (typeof productData?.productId === 'undefined') {
            return;
        }

        const product = this.tracker.createProduct(
            productData.productId,
            productData.name,
            productData.price,
            productData.currency,
            productData.quantity,
        );
        product.value = productData.value;

        if (productData.combinedProductId) {
            product.combinedProductId = productData.combinedProductId;
        }

        this.tracker.trackProductViewed(product);
    };

    private async processTrackingConsentInputs(): Promise<void> {
        const consentGrantedInput = document?.getElementById('js-consentGranted');
        const consentDeniedInput = document?.getElementById('js-consentDenied');

        if (!(consentGrantedInput instanceof HTMLInputElement) || !(consentDeniedInput instanceof HTMLInputElement)) {
            return;
        }

        if (await this.consentService.wasConsentGranted()) {
            consentGrantedInput.checked = true;
        } else if (await this.consentService.wasConsentDenied()) {
            consentDeniedInput.checked = true;
        }

        document?.addEventListener(this.CONSENT_GRANTED_EVENT, () => {
            consentGrantedInput.checked = true;
        });

        document?.addEventListener(this.CONSENT_DENIED_EVENT, () => {
            consentDeniedInput.checked = true;
        });

        consentDeniedInput.addEventListener('change', () => {
            if (consentDeniedInput.checked) {
                this.consentService.registerConsentDenied(
                    consentMethods.CONSENT_METHOD_USED_SETTINGS,
                    'User denied consent by submitting their preference through the consent tracking settings page',
                );
            }
        });

        consentGrantedInput.addEventListener('change', () => {
            if (consentGrantedInput.checked) {
                this.consentService.registerConsentGranted(
                    consentMethods.CONSENT_METHOD_USED_SETTINGS,
                    'User granted consent by submitting their preference through the consent tracking settings page',
                );

                this.triggerConsentGrantedEvent();
            }
        });

        // Only enable the inputs after registering the change handlers
        consentGrantedInput.disabled = false;
        consentDeniedInput.disabled = false;
    }

    private showAnalyticsInformation(): void {
        openCookieDialog({
            onDenyConsent: (blocked: boolean) => {
                this.consentService.registerConsentDenied(
                    consentMethods.CONSENT_METHOD_DENIED,
                    blocked
                        ? 'User denied consent by using a cookie banner blocker'
                        : 'User denied consent by declining within the tracking consent banner',
                );
            },
            onGrantConsent: () => {
                this.consentService.registerConsentGranted(
                    consentMethods.CONSENT_METHOD_APPROVED,
                    'User consented by approving within the tracking consent banner',
                );

                this.triggerConsentGrantedEvent();
            },
        });
    }

    /**
     * Facebook appends a 'fbclid' to outgoing links from their mobile application, but not always the proper UTM parameters.
     * This results in orders being attributed to 'direct' while it should've been attributed to Facebook
     * https://yoursurprise.atlassian.net/browse/YW-16061
     */
    private static isFacebookVisitWithMissingAttribution(url: URL): boolean {
        const { searchParams } = url;

        return searchParams.has('fbclid')
            && !searchParams.has('utm_source')
            && !searchParams.has('utm_medium')
            && !searchParams.has('utm_campaign');
    }

    /**
     * Manually append UTM params to make sure we can attribute it to Facebook
     * We don't know the campaign, this is preferable to not attributing it to Facebook at all
     */
    private static addFallbackFacebookUTMParameters(url: URL): void {
        const newUrl = new URL(url);

        newUrl.searchParams.set('utm_source', 'facebook');
        newUrl.searchParams.set('utm_medium', 'cpc');
        newUrl.searchParams.set('utm_campaign', 'unknown');

        window?.history.replaceState({}, '', newUrl.toString());
    }
}
